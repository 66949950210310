/*** App general styles */
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

a {
  text-decoration: none;
}

code {
  color: black;
}

.page {
  padding: 0 !important;
}

.fixed-bottom-right {
  @extend .fixed-bottom;

  left: initial;
  right: 0;
}

.form-check-label {
  cursor: pointer !important;
}

.custom-select {
  @extend .custom-select;
  border-radius: 0;
}

.custom-font-weight-light { font-weight: 300 !important; }
.custom-font-weight-regular { font-weight: 400 !important; }
.custom-font-weight-medium { font-weight: 500 !important; }
.custom-font-weight-bold { font-weight: 600 !important; }

body {
  margin: 0;  
  font-family: lega, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  background-attachment: fixed;

  /*** Default theming (for other thenes see *-theme.scss */
  &, &.background {
    background-image: url('../images/background.jpg') !important;
    background-repeat: no-repeat;
    background-size: cover;  
  }

  &.nobackground {
    background-image: none !important;
  }
}

@include media-breakpoint-down(sm) {
  .btn-block-xs-only {
    margin: 1rem auto;
    display: block;
    width: 100%;
  }
}