@import "bootstrap-theme.scss";
@import "~bootstrap/scss/bootstrap.scss";
@import '~react-multi-carousel/lib/styles.css';

@import "app.scss";
@import 'a-theme.scss';
@import "cpita-theme.scss";
@import "scup-theme.scss";
@import "sticky-footer.scss";

@import "@/components/conditions/conditions.scss";
@import "@/components/detail/detail.scss";
@import "@/components/footer/footer.scss";
@import "@/components/listing/listing.scss";
@import "@/components/listing-group/listing-group.scss";
@import "@/components/listing-slideshow/listing-slideshow.scss";
@import "@/components/loading/loading.scss";
@import "@/components/loading-button/loading-button.scss";
@import "@/components/login/login.scss";
@import "@/components/reset-user/reset-user.scss";
@import "@/components/navbar/navbar.scss";
@import "@/components/no-content/no-content.scss";
@import "@/components/profile/profile.scss";
@import "@/components/single-item/single-item.scss";
@import "@/components/standard-container/standard-container.scss";
@import "@/components/cornered-container/cornered-container.scss";
@import "@/components/report/report.scss";
@import "@/components/toaster/toaster.scss";
@import "@/components/content-select/content-select.scss";
@import "@/components/colored-corner/colored-corner.scss";
@import "@/components/select-language/select-language.scss";
