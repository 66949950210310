.conditions {
    background-image: url('/images/background.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    
    .modal-header {
        @extend .p-0;
        border: 0;

        .modal-title {
            .title {
                background-color: $secondary-color !important;
            }
        }
    }

    .modal-content, .modal-body, .modal-footer {
        background-color: $body-bg !important;
        color: $light-color;
        @extend .p-0;
        border: 0;

        .h4 {
            font-size: 1.25rem;
            line-height: 1;
        }
        
        a {
            color: $light-color;
        }
        input[type=checkbox] {
            accent-color: $light-color;
        }
        .select-language {
            max-width: 33%;
        }

        .btn {
            color: $light-color;
        }

    }
}