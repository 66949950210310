
.standard-container {
    position: relative;
    color: #333;
    background: #fff;
    padding: 3rem 1.5rem;

    @include media-breakpoint-down(sm) {
        margin: 0rem 0rem;
    }
    
    @include media-breakpoint-up(lg) {
        max-width: 50rem;
        margin: 0 auto;
    }

    &__title, input {
        margin-bottom: 1rem;
    }

    &__logo {
        position: absolute;
        border-radius: .5rem;

        top: -3rem;
        width: 3rem;
        left: calc(50% - 1.5rem);
    }
}