$container-width: 220px;
$image-scale-ratio: 1.1;

.content-select {
    margin-top: 15%;
    width: 60%;

    .content-select-title {
        @extend .mb-4;
        text-align: center;
        font-size: 2.5rem;
        color: $light-color;
    }

    .content-select-nav {
        .container {
            max-width: $container-width;
            min-width: $container-width;
            @extend .h-100;
            @extend .mx-2;
            @extend .mb-2;
            @extend .p-0;

            &:hover {
                transform: scale(1$image-scale-ratio, $image-scale-ratio);
                -ms-transform: scale($image-scale-ratio, $image-scale-ratio);
                -webkit-transform: scale($image-scale-ratio, $image-scale-ratio);
            }

            a {
                color: $body-color;
                text-decoration: none;
            }
            .logo {
                @include align-content-centered-middle;
                @extend .py-5;
                height: 82%;
                margin-bottom: 6px;
                @include align-content-centered-middle;
                img {
                    height: 125px;
                }
            }

            .caption {
                @include align-content-centered-middle;
                font-size: 1.75rem;
            }
        }

        .seriea {
            background-color: #171D8D;
        }

        .cita {
            background-color: #D91023;
        }

        .scita {
            background-color: #172EFF;
        }
    }


}