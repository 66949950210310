.toast {
  position: fixed;
  bottom: 1rem;
  left: 0;
  right: 0;
  margin: 0 auto;

  .close {
    margin-top: -3px;
  }
}
