/*** Supercoppa theme */
$scup-background: #005bff;
$scup-navbar: #02235C;
$scup-navbar-button: #005bff;
$scup-search-color: #005bff;
$scup-navbar-border: #005bff;
$scup-navbar-title: #00e8da;
$scup-divider: #01E8DA;
$scup-category: #fcfdfd;
$scup-category-menu: #02235C;
$scup-category-menu-marker: #005bff;
$scup-category-menu-gradient: #1D125D;
$scup-listing-badge-background: #171D8D;
$scup-listing-badge: #00e8da;
$scup-listing-item-corner: #00e8da;
$scup-detail-background: #02235C;
$scup-detail-header-background: #ffffff;
$scup-detail-match-name: #02235C;
$scup-detail-match-info: #02235C;
$scup-detail-divider: #0273FF;
$scup-detail-img-border: #00E8DA;
$scup-detail-close-color: #00e8da;
$scup-detail-close-background: #02235C;
$scup-detail-empty-cache-color: #00e8da;
$scup-detail-empty-cache-background: #02235C;
$scup-detail-download-color: #1d125d;
$scup-detail-download-background: #00e8da;
body {
    &.scita {
        background-image: url('../images/background-listing/supercoppa.jpg') !important;
        background-size: 100% auto;
        background-position-y: bottom;

        &.category-selected {
            background-image: none !important;
            background-color: $scup-background !important;
        }
        
        // Navbar
        nav.navbar {
            background-color: $scup-navbar !important;
            border-color: $scup-navbar-border !important;

            .icon-menu {
                border-color: $scup-divider;
            }

            .nav-item .dropdown {
                div.dropdown-menu.show {
                    background-color: rgba($scup-navbar, .4);

                    .dropdown-item,
                    .dropdown-item-text {
                        background-color: $scup-navbar-button;
                        color: $body-color;

                        .form-control {
                            background-color: $white;
                            color: $scup-navbar-button;

                            &::placeholder{
                                color: $scup-navbar-button;
                            }
                        }

                        &.dropdown-title {
                            background-color: transparent;
                            color: $scup-navbar-title;
                        }
                    }

                    .dropdown-item-text {
                        background-color: $scup-search-color;

                        .form-control {
                            color: $scup-search-color;

                            &::placeholder {
                                color: $scup-search-color;
                            }
                        }
                    }
                }
            }
        }

        // Listing
        .listing-container {
            .listing-header {
                border-color: $scup-navbar;
                .list-group-item .marker {
                    background-color: $scup-category-menu-marker;
                }
            }
    
            .listing-category-menu {
                background-color: $scup-category-menu;
                border-color: transparent;
            }
    
            .listing-group {
                .badge {
                  background-color: $scup-listing-badge-background;
                  color: $scup-listing-badge;
                }

                .image-item {
                    .cornered-container {
                        .colored-corner {
                            background-color: $scup-listing-item-corner;
                        }

                        .card {
                            background-color: $scup-navbar;
                        }
                    }
                }
            }
    
            .listing-group__name {
                color: $scup-category;
            }
        }

        // Detail
        .detail {
            .modal-header {
                background-color: $scup-detail-header-background;

                .modal-title {
                    .match-name {
                        color: $scup-detail-match-name;
                    }

                    .match-info {
                      color: $scup-detail-match-info;
                    }
                }

                .close-button {
                    background-color: $scup-detail-close-background;
                }
            }

            .modal-body {
                background-color: $scup-detail-background;

                img {
                    border-top: 1px $scup-detail-img-border solid;
                    padding: 7px 0px;
                    border-bottom: 1px $scup-detail-img-border solid;
                }
            }

            .modal-footer {
                background-color: $scup-detail-background;

                .empty-cache-button {
                    color: $scup-detail-empty-cache-color;
                    background: $scup-detail-empty-cache-background;
                }

                .download-button {
                    color: $scup-detail-download-color;
                    background: $scup-detail-download-background;

                    svg path {
                        fill: $scup-detail-download-color;
                    }
                }
            }
        }
    }
}
