/*** Serie A theme (default theme) */
$seriea-background: #02235C;
$seriea-navbar: #171D8D;
$seriea-navbar-button: #172EFF;
$seriea-search-color: #172EFF;
$seriea-navbar-border: #172EFF;
$seriea-navbar-title: #00e8da;
$seriea-divider: #00E8DA;
$seriea-category-menu: #02235C;
$seriea-category-menu-marker: #00E8DA;
$seriea-category: #ffffff;
$seriea-listing-badge-background: #172EFF;
$seriea-listing-badge: #ffffff;
$seriea-listing-item-corner: #00E8DA;
$seriea-detail-background: #171d8d;
$seriea-detail-header-background: #ffffff;
$seriea-detail-header-border: #02235C;
$seriea-detail-match-name: #02235C;
$seriea-detail-match-info: #02235C;
$seriea-detail-img-border: #00E8DA;
$seriea-detail-close-color: #00E8DA;
$seriea-detail-close-background: #1d125d;
$seriea-detail-empty-cache-color: #00E8DA;
$seriea-detail-empty-cache-background: #1d125d;
$seriea-detail-download-color: #1d125d;
$seriea-detail-download-background: #00E8DA;
body {
    &.a, &.default {
        background-image: url('../images/background-listing/serie-a.jpg') !important;
        background-size: 100% auto;
        background-position-y: bottom;

        &.category-selected {
            background-image: none !important;
            background-color: $seriea-background !important;
        }

        // Navbar
        nav.navbar {
            background-color: $seriea-navbar !important;
            border-color: $seriea-navbar-border !important;
            
            .icon-menu {
                border-color: $seriea-divider;
            }

            .nav-item .dropdown {
                div.dropdown-menu.show {
                    background-color: $seriea-navbar;

                    .dropdown-item,
                    .dropdown-item-text {
                        background-color: $seriea-navbar-button;
                        color: $body-color;

                        .form-control {
                            background-color: $white;
                            color: $seriea-navbar-button;

                            &::placeholder{
                                color: $seriea-navbar-button;
                            }
                        }

                        &.dropdown-title {
                            background-color: transparent;
                            color: $seriea-navbar-title;
                        }
                    }


                    .dropdown-item-text {
                        background-color: $seriea-search-color;

                        .form-control {
                            color: $seriea-search-color;

                            &::placeholder {
                            color: $seriea-search-color;
                            }
                        }
                    }

                }
            }
        }

        // Listing
        .listing-container {
            .listing-header {
                border-color: $seriea-divider;
                .list-group-item .marker {
                    background-color: $seriea-category-menu-marker;
                }
            }

            .listing-category-menu {
                background-color: $seriea-category-menu;
                border-color: $seriea-divider;
            }

            .listing-group {
                .badge {
                    background-color: $seriea-listing-badge-background;
                    color: $seriea-listing-badge;
                }

                .image-item {
                    .cornered-container {
                        .colored-corner {
                            background-color: $seriea-listing-item-corner;
                        }

                        .card {
                            background-color: $seriea-navbar;
                        }
                    }
                }
            }

            .listing-group__name {
                color: $seriea-category;
            }

        }

        // Detail
        .detail {
            .modal-header {
                background-color: $seriea-detail-header-background;

                .modal-title {
                    .match-name {
                        color: $seriea-detail-match-name;
                    }

                    .match-info {
                        color: $seriea-detail-match-info;
                    }
                }

                .close-button {
                    background-color: $seriea-detail-close-background;
                }
            }

            .modal-body {
                background-color: $seriea-detail-background;

                img {
                    border-top: 1px $seriea-detail-img-border solid;
                    padding: 7px 0px;
                    border-bottom: 1px $seriea-detail-img-border solid;
                }
            }

            .modal-footer {
                background-color: $seriea-detail-background;

                .empty-cache-button {
                    color: $seriea-detail-empty-cache-color;
                    background: $seriea-detail-empty-cache-background;
                }

                .download-button {
                    color: $seriea-detail-download-color;
                    background: $seriea-detail-download-background;

                    svg path {
                        fill: $seriea-detail-download-color;
                    }
                }
            }
        }
    }
}