.profile {
    .profile-card {
        .profile-header {
            span {
                @extend .p-1;
                background-color: #ffffff;
                color: #1d135d;
            }
        }
        
        .profile-content {
            background-color: rgba(#171D8D, 0.7);
            ul {
                list-style: none;
                padding-left: 1rem;
                color: $light-color;
        
                span {
                    @extend .text-primary, .font-weight-bold, .text-break;
                    color: $body-color !important;
                }
        
                a {
                    @extend .mt-1;
                    text-decoration: underline;
                }
            }
        }
        
        
    }
}

@include media-breakpoint-down(lg) {
    .profile-card {
        margin: 1rem;
    }
    .profile-content {
        padding: 1rem !important;
    }
    .language {
        min-width: 50%;
        max-width: 50%;
    }  
}

@include media-breakpoint-up(lg) { 
    .profile-card {
        width: 40%;
        margin: 3rem;
    }
    .profile-content {
        padding: 3rem !important;
    }
    .language {
        min-width: 40%;
        max-width: 40%;
    }
}