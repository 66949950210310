$image-scale-ratio: 1.1;

.listing-group {

  &__name {
    font-weight: $font-weight-bold;
    font-size: 1.5rem;
    white-space: nowrap;
    @include media-breakpoint-down(sm) {
      font-size: 1rem;
    }
    .badge {
      @extend .py-1;
      @extend .px-2;
      @extend .ml-3;
      font-size: 1.5rem;
      @include media-breakpoint-down(sm) {
        font-size: 1rem;
      }
    }
  }

  &__carousel {
    .card-title {
      font-size: 0.75rem;
      position: absolute;
      bottom: 0.5rem;
      margin-bottom: 0;
    }
  
    .card-text {
      font-size: 0.75rem;
      position: absolute;
      bottom: 0.5rem;
      right: 1rem;
      margin-bottom: 0;
      margin-right: 1.25rem;
    }
  
    .image-item {
      padding: 2rem;
    }

    .image-item:hover {
      transform: scale($image-scale-ratio, $image-scale-ratio);
      -ms-transform: scale($image-scale-ratio, $image-scale-ratio);
      -webkit-transform: scale($image-scale-ratio, $image-scale-ratio);
    }

  }
}


