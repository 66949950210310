$header-initial-height: 0px;
$header-min-height: 50vh;
$menu-width: 250px;

.listing-container {

  .listing-header {
    border-bottom: 2px solid;

    position: relative;
    min-height: $header-min-height;

    .listing-category-menu {
      min-width: $menu-width;
      max-width: $menu-width;
      min-height: $header-initial-height;
      border-top: 2px solid;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    
      .list-group-item, .list-group-item-action {
        @extend .py-1;
        background-color: transparent;
        color: $body-color;
        font-size: 1rem;
        border: none;
        white-space: nowrap;
        cursor: pointer;
      }
      .list-group-item.active {
        color: $light-color;
      }
      .list-group-item .marker {
        display: inline-block;
        vertical-align: middle;
        min-width: 15px;
        max-height: 3px;
      }
    }
  }
  
  .listing-content {
  }
}


