.navbar {
  @extend .p-0;
  border-bottom: 5px solid;
  max-height: $navbar-height;
  
  svg {
    width: 25px;
    height: 25px
  }

  .back-button {
    cursor: pointer;
  }

  .navbar-brand {
    @extend .m-0;
    img {
      width: 2.25rem;
    }
  }

  .navbar-menu-toggle {
    button {
      @extend .p-0;
      background-color: transparent;
      border: none;
    }
  }

  .icon-menu {
    @extend .h-100;
    @include align-content-centered-middle;
    border-bottom: 3px solid;
  }

  .navbar-right-menu {
    height: $navbar-height;
  }

  .nav-item .dropdown {
    div.dropdown-menu.show {
      min-width: 200px;
      margin-left: -100px !important;
      border: none;
      top: $dropdown-top;

      .dropdown-item,
      .dropdown-item-text {
        @extend .text-center;
        @extend .mb-4;

        &:last-child {
          @extend .mb-0;
        }

        &.dropdown-title {
          @extend .text-center;
          @extend .mt-0;
          @extend .mb-0;
          font-size: 1.25rem;
        }

        .form-control {
          text-align: center;
          border: none;
          border-radius: 5px;
          box-shadow: none;

          text-shadow: none;
          &::placeholder{ opacity: .6; }
          &:focus-visible {
            &::placeholder{ opacity: 1; }
          }
        }
      }
    }
  }
  .nav-item .input-group {
    .input-group-text {
      background-color: transparent;
      border: none;
    }
    input[type=text] {
      max-width: 120px;
    }
  }
  
}
