.reset-user {
    background-color: $body-bg;
    color: $light-color; 
    width: 450px;
    @extend .p-0;

    .reset-user-header {
        padding-top: 5%;
        padding-bottom: 5%;
    }

    .reset-user-title {
        text-align: center;
        font-size: 2.5rem;
        font-weight: 600;
        line-height: 1;

        label {
            background-color: $secondary-color;
        }
    }

    .reset-user-subtitle {
        text-align: center;
        font-size: 1.5rem;
    }

    .btn {
        color: $light-color;
        font-size: 1.5rem;
        font-weight: 600;
        padding: 0 5%;
    }
}