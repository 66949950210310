/*** Theme customization */

// Override default variables before the import
$body-bg: #02235c;
$body-color: #fff;
$enable-rounded: false;

// Basic background colors
$theme-colors: (
  primary: $body-bg,
  secondary: #171d8d,
  // secondary-light: #162cb7,
  // btn-background: #2047e3,
  // box-background: #18259c,
  light: #00E8DA,
  //navbar-border: #152db7,
) !default;

// Custom classes, mixins and variables
@mixin align-content-centered-middle {
  @extend .d-flex;
  @extend .align-items-center;
  @extend .justify-content-center;
}

.container-full {
  @extend .container;
  @extend .p-0;
  max-width: 100% !important;
  max-height: 100% !important;
}

$secondary-color: map-get($theme-colors, "secondary");
$light-color: map-get($theme-colors, "light");
$navbar-height: 70px;
$dropdown-top: 59px;