.detail {

    .modal-content {
        background: none;
    }

    .modal-header{
        position: relative;
        margin-bottom: 5px;

        .modal-title {
            margin: 0 auto;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow-x: hidden;
            text-align: center;

            .match-name {
                font-size: 1.5rem;
            }

            @include media-breakpoint-down(md) {
                font-size: 1.25rem;
            }

            @include media-breakpoint-down(sm) {
                font-size: 1rem;
            }
        }

        .close-button {
            width: fit-content;
            display: flex;
            position: absolute;
            right: 0;
            transform: translateX(-50%);

            opacity: .75;
            cursor: pointer;

            &:hover {
                opacity: 1;
            }
        }
    }

    .modal-body {
        display: flex;
        justify-content: center;
    }

    .modal-footer {
        justify-content: center;
        gap: 1rem;

        .empty-cache-button, .download-button {
            border: none;
        }
    }

    .modal-header, .modal-footer, .modal-body {
        border: none;
    }
}