.report {
    &__table {
        border-radius: .5rem;
        overflow: auto; 

        .table-responsive {
            max-height: 65vh;
        }

        thead th { 
            position: sticky; 
            top: 0; 
            z-index: 1; 
            background:#2047e3;
            white-space: nowrap;
        }

        td {
            background: #171d8d;
        }
    }

    hr {
        background-color: #18259c;
    }
}
