.select-language {
  display: flex;
  flex-direction: column;
  position: relative;
  
  cursor: pointer;
  user-select: none;

  font-size: $font-size-lg;

  &.dropdown-opened {
    .active {
      .arrow-icon {
        transform: rotate(180deg);
      }
    }
    .not-active {
      display: block;
    }
  }

  .active {
    order: 1;

    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: $white;
    color: $seriea-background;

    .arrow-icon {
      display: inline-flex;
      width: 20px;
      height: 20px;
    }
  }

  .not-active {
    order: 2;
    display: none;
    position: absolute;

    top: 100%;
    width: 100%;

    background-color: $secondary-color;
    color: $light-color;
  }
}

.profile-content .select-language .not-active { background-color: #02235C; }