$colored-corner-dim: 75px;
$colored-corner-margin-horizontal: -3px;
$colored-corner-margin-vertical: -4px;

@mixin colored-corner {
  position: absolute;
  width: $colored-corner-dim;
  height: $colored-corner-dim;
  z-index: -1;
}

.colored-corner {
  background-color: $light-color;
}

.top-left-colored-corner {
  @include colored-corner();

  top: $colored-corner-margin-vertical;
  left: $colored-corner-margin-horizontal;
}

.top-right-colored-corner {
  @include colored-corner();

  top: $colored-corner-margin-vertical;
  right: $colored-corner-margin-horizontal;
}

.bottom-left-colored-corner {
  @include colored-corner();

  bottom: $colored-corner-margin-vertical;
  left: $colored-corner-margin-horizontal;
}

.bottom-right-colored-corner {
  @include colored-corner();

  bottom: $colored-corner-margin-vertical;
  right: $colored-corner-margin-horizontal;
}
