.single-item {
  &__card {
    &:hover {
        opacity: 1;
    }
  }

  .card {
    border: none;
  }
}

.loading-failed {
  height: 100%;

  .single-item {
    .card {
      min-height: 300px;
      height: 100%;

      .card-img {
        display: none;
      }
    }
  }
}