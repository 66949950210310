/*** Coppa Italia theme */
$cpita-background: #590303;
$cpita-navbar: #D91023;
$cpita-navbar-button: #ffffff;
$cpita-search-color: #D91023;
$cpita-navbar-border: #08c779;
$cpita-navbar-title: #fff;
$cpita-navbar-text: #590303;
$cpita-divider: #ffffff;
$cpita-category: #ffffff;
$cpita-category-menu: #590303;
$cpita-category-menu-gradient: #780202;
$cpita-category-menu-marker: #08c779;
$cpita-listing-badge-background: #D91023;
$cpita-listing-badge: #ffffff;
$cpita-listing-item-corner: #FF4432;
$cpita-detail-background: #a10000;
$cpita-detail-header-background: #ffffff;
$cpita-detail-header-border: transparent;
$cpita-detail-match-name: #02235C;
$cpita-detail-match-info: #02235C;
$cpita-detail-img-border: #08c779;
$cpita-detail-close-color: #00e8da;
$cpita-detail-close-background: #590303;
$cpita-detail-empty-cache-color: #ffffff;
$cpita-detail-empty-cache-background: #590303;
$cpita-detail-download-color: #ffffff;
$cpita-detail-download-background: #08c779;
body {
    &.cpita {
      background-image: url('../images/background-listing/coppa-italia.jpg') !important;
      background-size: 100% auto;
      background-position-y: bottom;

      &.category-selected {
          background-image: none !important;
          background-color: $cpita-background !important;
      }

    // Navbar
    nav.navbar {
      background-color: $cpita-navbar !important;
      border-color: $cpita-navbar-border !important;

      .icon-menu {
          border-color: $cpita-divider;
      }

      .nav-item .dropdown {
        div.dropdown-menu.show {
            background-color: $cpita-navbar;

            .dropdown-item,
            .dropdown-item-text {
                background-color: $cpita-navbar-button;
                color: $cpita-navbar-text;

                .form-control {
                  background-color: $white;
                  color: $cpita-navbar-button;

                  &::placeholder{
                    color: $cpita-navbar-button;
                  }
                }

                &.dropdown-title {
                  background-color: transparent;
                  color: $cpita-navbar-title;
              }
            }

            .dropdown-item-text {
              background-color: $cpita-search-color;

              .form-control {
                color: $cpita-search-color;

                &::placeholder {
                  color: $cpita-search-color;
                }
              }
            }
        }
      }
    }

    // Listing
    .listing-container {
      .listing-header {
          border-color: $cpita-divider;
          .list-group-item .marker {
            background-color: $cpita-category-menu-marker;
        }
      }

      .listing-category-menu {
          background-color: $cpita-category-menu;
          border-color: $cpita-divider;
      }

      .listing-group {
          .badge {
            background-color: $cpita-listing-badge-background;
            color: $cpita-listing-badge;
          }

          .image-item {
            .cornered-container {
                .colored-corner {
                    background-color: $cpita-listing-item-corner;
                }

                .card {
                  background-color: $cpita-navbar;
                }
            }
        }
      }

      .listing-group__name {
          color: $cpita-category;
      }
    }

    // Detail
    .detail {
      .modal-header {
        background-color: $cpita-detail-header-background;
        border-color: $cpita-detail-header-border;
        border-width: 5px;

        .modal-title {
          .match-name {
            color: $cpita-detail-match-name;
          }

          .match-info {
            color: $cpita-detail-match-info;
          }
        }

        .close-button {
          background-color: $cpita-detail-close-background;
        }
      }

      .modal-body {
        background-color: $cpita-detail-background;

        img {
          border-top: 1px $cpita-detail-img-border solid;
          padding: 7px 0px;
          border-bottom: 1px $cpita-detail-img-border solid;
        }
      }

      .modal-footer {
        background-color: $cpita-detail-background;

        .empty-cache-button {
          color: $cpita-detail-empty-cache-color;
          background: $cpita-detail-empty-cache-background;
        }

        .download-button {
          color: $cpita-detail-download-color;
          background: $cpita-detail-download-background;

          svg path {
            fill: $cpita-detail-download-color;
          }
        }
      }
    }
  }
}