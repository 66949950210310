@font-face {
  font-family: 'lega';
  src: local('lega'), url(../fonts/Barlow-Regular.ttf) format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'lega';
  src: local('lega'), url(../fonts/Barlow-Bold.ttf) format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'lega';
  src: local('lega'), url(../fonts/Barlow-Light.ttf) format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'lega';
  src: local('lega'), url(../fonts/Barlow-Medium.ttf) format('truetype');
  font-weight: 500;
}