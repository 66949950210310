.listing-slideshow {
  user-select: none;
  pointer-events: none;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;

  > ul {
    height: 100%;
  }

  .listing-slideshow-item {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
